import { Box, Divider, Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Layout, Seo } from "../../components";
import "../../styles/screen.scss";

const seoBundle = {
  title: "The Steel Construction Process in Boise, Idaho",
  subtitle: "JMF Steel Erectors",
  description:
    "Steel Construction Process for Small to Mid-sized Commercial Buildings or Industrial Units",
  slug: "/articles/steel-erectors-boise",
};

const Blog1 = ({ location }) => {
  return (
    <Layout title={"Home"} location={location}>
      <Seo seoBundle={seoBundle} />
      <article>
        <Flex
          maxW='1450px'
          h='100%'
          // header takes up 40/50 pixels
          pt={["150px", null, "150px"]}
          px={["20px", null, "100px"]}
          pb={["60px", null, "100px"]}
          align='center'
          justify='center'
          flexDirection='column'
        >
          <Heading
            as='h1'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            mt='0px'
            fontWeight={800}
            fontSize={["3em", "4em"]}
            fontFamily='heading'
            lineHeight={["100%", null, null, "120%"]}
            textAlign='center'
          >
            Steel Construction Process in Boise, Idaho
          </Heading>
          <Heading
            as='h2'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            mt='0px'
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            From Start to Finish. How the Steel Erection Process Works.
          </Heading>
          <Flex flexDir='row'>
            <Text my='30px' fontWeight={600} textAlign='center'>
              “It's a complex process that requires skill, precision, and a
              whole lot of coordination, but the end result? Absolutely worth
              it.”
            </Text>
          </Flex>
          <HStack display='flex' align='center'>
            <Box
              w='100%'
              display='flex'
              h={["60px"]}
              w={["60px"]}
              justifyContent='center'
              mr='10px'
            >
              <StaticImage
                src='../images/jake.jpg'
                alt='Jake Fillmore, owner and CEO'
                placeholder='blurred'
                layout='constrained'
                style={{ borderRadius: "50%" }}
              />
            </Box>
            <Flex flexDir='column'>
              <Text fontFamily='heading' mb='0' fontWeight={600}>
                Jake Fillmore
              </Text>
              <Text as='span' mt='0' pt='0' fontSize='12px'>
                CEO, Owner & Operator <br />
                <Text fontFamily='heading' fontWeight={700}>
                  JMF Steel Construction
                </Text>
              </Text>
            </Flex>
          </HStack>
          <Flex
            w='100%'
            my={["30px"]}
            h={["200px", null, "200px", "400px", "600px"]}
            // w={["250px", "350px", "150px", "250px", "350px"]}
            justifyContent='center'
          >
            <StaticImage
              src='../../images/storage.jpg'
              alt='roof top garden in large city'
              placeholder='blurred'
              // height={200}
              // width={200}
              // height={200}
            />
          </Flex>
          <Text
            mt='20px'
            mb='7'
            className='cap'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            Over the years, we've been blessed to build countless structures
            using steel erector construction, or as we call it, structural steel
            erection. Now, if you're scratching your head wondering what that
            is, let me break it down. It's about piecing together the skeleton
            of a structure - think small to mid-sized commercial buildings or
            industrial units - with steel components. Let me walk you through
            the process that's been my bread and butter for decades.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Getting the Blueprint Right
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Every masterpiece begins with a sketch, right? That's what the
            planning and design phase is all about. There's several businesses
            that can draw up a design from your ideas. Some great local
            companies would be <a href='https://rmsteel.com'>R & M Steel</a>{" "}
            <a href='tel:2084541800'>(208) 454-1800</a> or{" "}
            <a href='https://www.crolledsteel.com'>Cold Rolled Steel</a>{" "}
            <a href='tel:2084557477'>(208) 455-7477</a>. Both offer excellent
            service and products. This isn't just about creating a good-looking
            building. They factor in safety standards, load-bearing capacity,
            and materials - it's a whole science in itself. These days, they're
            leveraging computer-aided design (CAD) systems. It's like having a
            crystal ball that helps us anticipate potential issues and ensure
            everything fits just right.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            The Art of Fabrication
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Once they've got your design blueprint, they dive into the
            fabrication phase. This involves shaping steel components to match
            the design specifications. I've seen this process evolve over the
            years, and it's all about precision, precision, precision. Companies
            typically gets these components from steel mills or service centers.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Transporting the Big Guns
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            With fabrication done, the next challenge is getting these steel
            components to the construction site. Now, this ain't no ordinary
            delivery - it's like moving a mountain. Specialized vehicles come
            into play here, and the logistics need to be spot on to ensure
            timely delivery.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Bringing the Structure to Life
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Now comes the part fun part. It's like watching a child's drawing
            come to life as our skilled ironworkers assemble the steel
            components following the design plan. This process kicks off with
            the frame construction, and then we gradually add the main
            structural elements.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Safety - Our Top Priority
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            As exciting as this process is, it comes with its share of risks.
            That's why safety is our top priority. I've always made sure my crew
            uses safety harnesses and follows the Occupational Safety and Health
            Administration (OSHA) standards for steel erection. We look out for
            each other, and that's non-negotiable.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            The Ties that Bind: Bolting and Welding
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Once all the components are in place, we bolt or weld them together.
            This process strengthens the structure, making sure it's sturdy
            enough to withstand the intended loads. Trust me, it takes a skilled
            hand to get this right.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Final Inspection and Finishing: The Cherry on Top
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            After the steel structure is standing tall, we conduct a final
            inspection. This is our quality check to ensure the structure meets
            all safety and design standards. Once we've dotted our i's and
            crossed our t's, we move on to the finishing touches - roofing,
            cladding, windows, and doors.
          </Text>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            It's a complex process that requires skill, precision, and a whole
            lot of coordination, but the end result? Absolutely worth it. From
            an idea on paper to a steel structure that stands tall, it's a
            process I've been proud to be a part of
          </Text>

          <Divider maxW='600px' my='40px' orientation='horizontal' />
          <Heading as='h3' textAlign='center'>
            JMF Construction
          </Heading>
          <Heading as='h4' fontFamily='body' fontSize='16px'>
            A Boise, Idaho Company
          </Heading>
          <Text
            mt='20px'
            mb='7'
            textAlign='center'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            We’ve done projects all over the Treasure Valley and beyond for
            businesses such as Simplot Western Stockmen’s, CSDI, Champion
            Produce in Parma, and Farm Equipment USA in Fruitland and Jerome.
            Have a small project? You’ve come to the right place. We build
            smaller projects regularly, not just the large scale ones.
          </Text>
          <Text
            mt='20px'
            mb='7'
            textAlign='center'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            Here at JMF, we make it our business to get to know you and your
            unique project. Call us today!
          </Text>
          <Text
            mb='40px'
            mt='20px'
            fontSize={24}
            textAlign='center'
            maxW='800px'
            lineHeight='120%'
            fontWeight='bold'
            color='dkGrey'
          >
            Call us at <a href='tel:2088801062'>(208) 880-1062</a>
          </Text>
        </Flex>
      </article>
    </Layout>
  );
};

export default Blog1;
